import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { Modal } from "react-bootstrap";
import { MessageSquare, Send } from "lucide-react";
import { toast } from "sonner";

interface Chamado {
  descricao: string;
  email: string;
  empresa: string;
  num_Chamado: string;
  telefone: string;
  tipoSolicitacao: string;
  resposta: string;
  uuid: string;
}

const Chamados = () => {
  const [chamados, setChamados] = useState<Chamado[] | string>([]);
  const [modalShow, setModalShow] = useState(false);
  const [resposta, setResposta] = useState(""); // Estado para armazenar a resposta
  const [chamadoAtual, setChamadoAtual] = useState<Chamado | null>(null); // Chamado atual para responder

  const token = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    handleLoadChamados();

    document.title = "Íkone | Chamados";
  }, [token, navigate]);

  const handleLoadChamados = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/Chamado", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        throw new Error(errorData.mensagem);
      }

      const data: Chamado[] = await response.json();
      setChamados(data);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Erro ao buscar chamados:", error.message);
        setChamados("Houve um erro ao buscar chamados");
      }
    }
  };

  const handleResponderChamado = async () => {
    if (!resposta.trim()) {
      toast.error("A resposta não pode estar vazia.");
      return;
    }

    try {
      const response = await fetch(
        `http://localhost:5000/api/Chamado/${chamadoAtual?.uuid}/responder`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(resposta),
        }
      );

      if (response.ok) {
        toast.success("Resposta enviada com sucesso.");
        setTimeout(() => setModalShow(false), 3000);
        setResposta(""); // Limpar a resposta após o envio
        handleLoadChamados(); // Atualizar os chamados
      } else {
        const errorData = await response.json();
        console.error(errorData);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Erro ao responder chamado:", error.message);
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center text-primary fw-bold">📌 Lista de Chamados</h2>

      <div className="row row-cols-1 row-cols-md-2 g-4 mt-4">
        {Array.isArray(chamados) ? (
          chamados.map((chamado) => (
            <div key={chamado.uuid} className="col">
              <div className="card shadow-sm border-0 rounded-4">
                <div className="card-body">
                  <h5 className="card-title fw-bold text-dark">
                    {chamado.num_Chamado
                      ? `Chamado #${chamado.num_Chamado}`
                      : "Chamado: N/A"}
                  </h5>
                  <p className="text-muted">
                    <strong>📌 Empresa:</strong> {chamado.empresa}
                  </p>
                  <p className="text-muted">
                    <strong>✉️ Email:</strong> {chamado.email}
                  </p>
                  <p className="text-muted">
                    <strong>📞 Telefone:</strong> {chamado.telefone}
                  </p>
                  <p className="text-muted">
                    <strong>📋 Tipo de Solicitação:</strong>{" "}
                    {chamado.tipoSolicitacao}
                  </p>
                  <p className="text-muted">
                    <strong>📝 Descrição:</strong> {chamado.descricao}
                  </p>
                  <p className="text-muted">
                    <strong>💬 Resposta:</strong>{" "}
                    {chamado.resposta || "Ainda sem resposta"}
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      setModalShow(true);
                      setChamadoAtual(chamado);
                    }}
                    className="btn btn-outline-primary d-flex align-items-center gap-2"
                  >
                    <MessageSquare size={18} />
                    Responder
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex justify-content-center w-100">
            <p className="text-danger">Nenhum chamado disponível</p>
          </div>
        )}
      </div>

      {/* Modal para responder */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>✍️ Responder Chamado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="resposta" className="form-label fw-bold">
              💬 Sua Resposta:
            </label>
            <textarea
              id="resposta"
              className="form-control border rounded-3"
              rows={5}
              value={resposta}
              onChange={(e) => setResposta(e.target.value)}
              placeholder="Digite sua resposta aqui..."
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setModalShow(false)}
          >
            Fechar
          </button>
          <button
            className="btn btn-primary d-flex align-items-center gap-2"
            onClick={handleResponderChamado}
          >
            <Send size={18} />
            Enviar Resposta
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Chamados;
