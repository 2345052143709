import React from "react";

import './../styles/NossosProdutos.css'

const NossosProdutos = () => {
  const produtos = [
    {
      id: 1,
      nome: "Produto 1",
      descricao: "Descrição do produto 1",
      imagem:
        "https://as1.ftcdn.net/v2/jpg/06/12/66/98/1000_F_612669885_nOCZzlrwcHoxTLB9svPGH1UGR08iE810.jpg",
    },{
      id: 2,
      nome: "Produto 1",
      descricao: "Descrição do produto 1",
      imagem:
        "https://as1.ftcdn.net/v2/jpg/06/12/66/98/1000_F_612669885_nOCZzlrwcHoxTLB9svPGH1UGR08iE810.jpg",
    },{
      id: 2,
      nome: "Produto 1",
      descricao: "Descrição do produto 1",
      imagem:
        "https://as1.ftcdn.net/v2/jpg/06/12/66/98/1000_F_612669885_nOCZzlrwcHoxTLB9svPGH1UGR08iE810.jpg",
    }
  ];

  return (
    <div className="container mt-4" id="produtos">
      <h1 data-aos="fade-up">Nossos Produtos</h1>
      <div className="d-flex gap-3 mt-4 flex-wrap justify-content-lg-start justify-content-md-start justify-content-sm-center">
        {produtos.map((produto) => (
          <div key={produto.id} data-aos="fade-left" className="position-relative produto-container">
            <img
              src={produto.imagem}
              alt=""
              loading="lazy"
              className="object-fit-cover position-relative rounded"
              width={"300px"}
              height={"400px"}
            />
            <h3 className="position-absolute .produto-nome bottom-0 text-white px-3 pb-2">{produto.nome}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NossosProdutos;
