import { useEffect } from "react";

const NaoEncontrada = () => {
  useEffect(() => {
    document.title = "Íkone | Pagina Nao Encontrada";
  }, []);

  return (
    <div className="h-screen d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h1>Pagina Não Encontrada</h1>
        <p>O conteudo solicitado não foi encontrado</p>
      </div>
    </div>
  );
};

export default NaoEncontrada;
