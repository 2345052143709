import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Usuario {
  ativacao: number;
  ativo: number;
  chave: string | null;
  cpf: string | null;
  dataAlteracao: string;
  dataCadastro: string;
  dataExclusao: string;
  email: string;
  id: number;
  login: string;
  nivel: string;
  nome: string;
  reset: string;
  senha: string;
}

interface AuthState {
  token: string | null;
  user: Usuario | null;
}

const initialState: AuthState = {
  token: localStorage.getItem("token") || null,
  user: localStorage.getItem("_user")
    ? JSON.parse(localStorage.getItem("_user") as string)
    : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    setUser: (state, action: PayloadAction<Usuario>) => {
      state.user = action.payload;
      localStorage.setItem("_user", JSON.stringify(action.payload));
    },
    clearToken: (state) => {
      state.token = null;
      localStorage.removeItem("token");
    },
  },
});

export const { setToken, setUser, clearToken } = authSlice.actions;
export default authSlice.reducer;
