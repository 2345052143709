import { Routes, Route } from "react-router-dom";
import NaoEncontrada from "./pages/(public)/NaoEncontrada";
import Inicio from "./pages/Inicio";
import Chamado from "./pages/(private)/Chamado";
import Chamados from "./pages/(private)/Chamados";
import ProtectedRoute from "./components/ProtectedRoute";
import AreaDoCliente from "./pages/(public)/AreaDoCliente";

const Router = () => {
  return (
    <Routes>
      <Route index element={<Inicio />} />
      <Route path="/area-do-cliente" element={<AreaDoCliente />} />

      <Route element={<ProtectedRoute />}>
        <Route path="/chamados" element={<Chamados />} />
        <Route path="/chamado" element={<Chamado />} />
      </Route>

      <Route path="*" element={<NaoEncontrada />} />
    </Routes>
  );
};

export default Router;
