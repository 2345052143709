import { useState, useRef, useEffect } from "react";
import { ChevronDown, ChevronUp, LogOut, Settings, User } from "lucide-react";
import { clearToken } from "../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserMenu = ({ user, imagem }: { user: string; imagem: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearToken()); // Remove o token do Redux
    navigate("/"); // Redireciona para a página inicial ou de login
  };

  // Fechar ao clicar fora
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="dropdown" ref={menuRef}>
      <button
        title="User Menu"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="d-flex align-items-center p-2 rounded-circle border-0 bg-transparent"
      >
        <img
          src={
            "https://ui-avatars.com/api/?name=A&background=00ff00&color=ffffff&size=128"
          }
          alt="User"
          width={40}
          loading="lazy"
          height={40}
          className="w-10 h-10 rounded-circle"
        />
        {!isOpen ? (
          <ChevronDown className="text-black" />
        ) : (
          <ChevronUp className="text-black" />
        )}
      </button>

      {isOpen && (
        <div
          className="dropdown-menu dropdown-menu-end show w-100"
          aria-labelledby="user-menu"
        >
          <div className="p-3 border-bottom">
            <p className="text-black font-weight-bold">Alex</p>
            <p className="text-muted text-sm">{user}</p>
          </div>

          <ul className="list-unstyled">
            <li
              onClick={() => {
                navigate("/chamado");
              }}
              className="dropdown-item d-flex align-items-center cursor-pointer"
            >
              <User className="w-4 h-4 mr-2" /> Abrir chamado
            </li>
            <li
              onClick={() => {
                navigate("/chamados");
              }}
              className="dropdown-item d-flex align-items-center cursor-pointer"
            >
              <Settings className="w-4 h-4 mr-2" /> Meus Chamados
            </li>
            <li
              onClick={handleLogout}
              className="dropdown-item d-flex align-items-center text-danger cursor-pointer"
            >
              <LogOut className="w-4 h-4 mr-2" /> Sair
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
