import React, { useState } from "react";
import { Modal } from "react-bootstrap"; // Utilizando o modal do Bootstrap
import "./../styles/InfiniteCarousel.css";

// Importando as imagens diretamente
import bhLogo from "../assets/img/empresasClientes/aeroportos/bh.png";
import ccrLogo from "../assets/img/empresasClientes/aeroportos/ccr.png";
import gruLogo from "../assets/img/empresasClientes/aeroportos/gru.png";
import fraportLogo from "../assets/img/empresasClientes/aeroportos/fraport.png";
import infraeroLogo from "../assets/img/empresasClientes/aeroportos/infraero.png";
import riogaleaoLogo from "../assets/img/empresasClientes/aeroportos/riogaleao.png";
import vcpLogo from "../assets/img/empresasClientes/aeroportos/vcp.png";
import cubanaLogo from "../assets/img/empresasClientes/demais clientes/135-1359366_domestic-flights-cuba-cubana-de-aviacin.png";
import aerolineasLogo from "../assets/img/empresasClientes/demais clientes/Aerolíneas_Argentinas_logo.png";
import assaiLogo from "../assets/img/empresasClientes/demais clientes/Assai.svg.png";
import dermaskinLogo from "../assets/img/empresasClientes/demais clientes/dermaskin logo.png";
import dnataLogo from "../assets/img/empresasClientes/demais clientes/dnata_logo.png";
import emiratesLogo from "../assets/img/empresasClientes/demais clientes/emirates-logo-4.png";
import hubLogo from "../assets/img/empresasClientes/demais clientes/hub.jpg";
import arcRoseOfficeLogo from "../assets/img/empresasClientes/demais clientes/LOGO ARC ROSEOFFICE.jpg";
import agraLogo from "../assets/img/empresasClientes/demais clientes/logotipo agra.png";
import passaredoLogo from "../assets/img/empresasClientes/demais clientes/passaredo-logomarca.jpg";
import skyAirlinesLogo from "../assets/img/empresasClientes/demais clientes/sky airline.png";
import titanLogo from "../assets/img/empresasClientes/demais clientes/titan-log-logo.png";

const InfiniteCarousel = () => {
  // Estado para controlar o modal
  const [modalShow, setModalShow] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState<string | null>(null);

  // Função para abrir o modal e mostrar o logo clicado
  const handleLogoClick = (logoSrc: string) => {
    setSelectedLogo(logoSrc);
    setModalShow(true);
  };

  const logos = [
    bhLogo,
    ccrLogo,
    gruLogo,
    fraportLogo,
    infraeroLogo,
    riogaleaoLogo,
    vcpLogo,
    cubanaLogo,
    aerolineasLogo,
    assaiLogo,
    dermaskinLogo,
    dnataLogo,
    emiratesLogo,
    hubLogo,
    arcRoseOfficeLogo,
    agraLogo,
    passaredoLogo,
    skyAirlinesLogo,
    titanLogo,
  ];

  // Criando um efeito infinito duplicando a lista de imagens
  const infiniteLogos = [...logos, ...logos];

  return (
    <div className="container mt-4">
      <h1 className="title" data-aos="fade-up">Nossos Clientes</h1>
      <div className="logos">
        <div className="logos-slide">
          {infiniteLogos.map((logoSrc, index) => (
            <img
              key={index}
              src={logoSrc}
              alt={`Logo ${index}`}
              data-aos="fade-right"
              onClick={() => handleLogoClick(logoSrc)}
              className="logo-item"
            />
          ))}
        </div>
        <div className="logos-slide">
          {infiniteLogos.map((logoSrc, index) => (
            <img
              key={index}
              src={logoSrc}
              data-aos="fade-left"
              alt={`Logo ${index}`}
              onClick={() => handleLogoClick(logoSrc)}
              className="logo-item"
            />
          ))}
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Informações do Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedLogo || ""}
            alt="Logo Selecionado"
            className="modal-logo"
          />
          <p>Detalhes do cliente aqui...</p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InfiniteCarousel;
