import UserMenu from "./UserMenu";
import logo from "./../assets/ikoneLogo.png";
import { useState } from "react";

function Navegacao({
  userId,
  imageUrl,
}: {
  userId: string | null;
  imageUrl: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {userId ? (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm p-3">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center w-75 mx-auto">
              {/* Logo ou título */}
              <div className="d-flex align-items-center">
                <a href="/" title="EDOCTORS">
                  <img src={logo} alt="Logo" />
                </a>
              </div>

              {/* Botões à direita (Desktop) */}
              <div className="d-flex gap-3">
                {userId && (
                  <>
                    <UserMenu user={userId} imagem={imageUrl} />
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm p-3">
          <div className="container-fluid">
            {/* Logo */}
            <a
              className="navbar-brand d-flex align-items-center"
              href="/"
              title="EDOCTORS"
            >
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ maxHeight: "40px", marginLeft: "2rem" }}
              />
            </a>

            {/* Botão do menu hamburger */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
              aria-label="Alternar navegação"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Itens do menu */}
            <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
              <ul
                className={`navbar-nav ms-auto d-flex me-5 justify-content-start ${
                  isOpen ? "mb-3 mt-3" : ""
                } gap-3`}
              >
                <li className="nav-item">
                  <a className="nav-link" href="#produtos">
                    Produtos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contato">
                    Contato
                  </a>
                </li>
              </ul>

              {/* Área do Cliente */}
              <div className={`d-flex align-items-center gap-3`}>
                <a
                  className={`btn btn-primary ${isOpen ? "w-100" : ""}`}
                  href="/area-do-cliente"
                >
                  Área do Cliente
                </a>

                {/* Menu do usuário (se logado) */}
                {userId && <UserMenu user={userId} imagem={imageUrl} />}
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
}

export default Navegacao;
