import React, { useEffect, useState } from "react";
import Navegacao from "../../components/Nav";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Loading from "../../components/Loading";
import { toast } from "sonner";

const Chamado = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    document.title = "Íkone | Abrir Chamado";
  }, [token, navigate]);

  const [formData, setFormData] = useState({
    empresa: "",
    descricao: "",
    num_chamado: "",
    tipo_solicitacao: "",
    telefone: "",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleChangeTipoSolicitacao = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, tipo_solicitacao: e.target.value });
  };

  const handleChangeTelefone = (e: React.ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value.replace(/\D/g, "");

    if (input.length > 10) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(
        7,
        11
      )}`;
    } else if (input.length > 5) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 6)}-${input.slice(6)}`;
    } else if (input.length > 2) {
      input = `(${input.slice(0, 2)}) ${input.slice(2)}`;
    } else if (input.length > 0) {
      input = `(${input}`;
    }

    setFormData({ ...formData, telefone: input });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && formData.telefone.length === 1) {
      setFormData({ ...formData, telefone: "" });
    }

    if (
      e.key === "Backspace" &&
      formData.telefone.length >= 10 &&
      formData.telefone[formData.telefone.length - 1] === "-"
    ) {
      setFormData({ ...formData, telefone: formData.telefone.slice(0, -1) });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      // Enviando os dados com as chaves corretas conforme esperado pela API
      await fetch("http://localhost:5000/api/Chamado", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uuid: "", // Suponho que o UUID seja gerado ou vindo de algum lugar
          empresa: formData.empresa,
          num_Chamado: formData.num_chamado,
          email: formData.email,
          telefone: formData.telefone,
          tipoSolicitacao: formData.tipo_solicitacao,
          descricao: formData.descricao,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          toast.success("Chamado criado com sucesso!");
          if (data) {
            navigate("/chamados");
          } else {
            alert(data.mensagem);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Navegacao userId={token} imageUrl="b" />
      <div className="container-fluid p-0">
        <div className="bg-dark p-5 text-light text-center">
          <h1>Solicitações/Chamados ÍKONE Inspeciona</h1>
          <p className="py-3 px-md-5">
            Este formulário deve ser utilizado por cliente ÍKONE, ou por pessoas
            que as representam para registrar chamados de falhas ou melhorias
            nos sistemas.
          </p>
        </div>
        <div className="d-flex flex-column align-items-center py-5 bg-light">
          <form
            onSubmit={handleSubmit}
            className="w-75 bg-white p-4 shadow-lg rounded"
          >
            <div className="mb-3">
              <label htmlFor="empresa" className="form-label">
                Nome da Empresa <span className="text-danger">*</span>
              </label>
              <input
                id="empresa"
                className="form-control"
                type="text"
                placeholder="Digite o nome da empresa"
                value={formData.empresa}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="num_chamado" className="form-label">
                Número Chamado Interno
              </label>
              <input
                id="num_chamado"
                className="form-control"
                type="text"
                placeholder="Digite o número do chamado"
                value={formData.num_chamado}
                onChange={handleChange}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email para contato <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                placeholder="Email"
                disabled
                value={user?.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="telefone" className="form-label">
                Telefone <span className="text-danger">*</span>
              </label>
              <input
                type="tel"
                id="telefone"
                value={formData.telefone}
                onChange={handleChangeTelefone}
                onKeyDown={handleKeyDown}
                className="form-control"
                placeholder="Telefone"
                required
              />
            </div>

            <div className="mb-3">
              <label className="form-label">
                O tipo de solicitação/chamado é:{" "}
                <span className="text-danger">*</span>
              </label>
              {[
                "Prioridade Alta - Periférico Danificado",
                "Prioridade Alta - Sistema com erro ou indisponível",
                "Prioridade Alta - Integração WMS Recintos",
                "Prioridade Média - Dúvidas/Suporte",
                "Prioridade Baixa - Melhorias",
              ].map((label, index) => (
                <div className="form-check" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="tipo_solicitacao"
                    id={`opcao${index}`}
                    value={label}
                    checked={formData.tipo_solicitacao === label}
                    onChange={handleChangeTipoSolicitacao}
                  />
                  <label className="form-check-label" htmlFor={`opcao${index}`}>
                    {label}
                  </label>
                </div>
              ))}
            </div>

            <div className="mb-3">
              <label htmlFor="descricao" className="form-label">
                Descrição <span className="text-danger">*</span>
              </label>
              <textarea
                id="descricao"
                className="form-control"
                rows={4}
                placeholder="Descreva o chamado/solicitação de forma detalhada"
                value={formData.descricao}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-primary mt-3 w-100"
            >
              {isLoading ? (
                <Loading isButton={true} corPredefinida color="white" />
              ) : (
                "Entrar"
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Chamado;
