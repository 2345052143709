import clsx from "clsx";

interface LoadingProps {
  isButton?: boolean;
  isPage?: boolean;
  corPredefinida?: boolean;
  color: string;
}

const Loading: React.FC<LoadingProps> = ({
  isButton,
  isPage,
  corPredefinida,
  color,
}) => {
  if (!isButton && !isPage) return null; // Se nenhum for true, não renderiza nada

  const size = isButton ? "spinner-border-sm" : ""; // Tamanho do spinner do Bootstrap
  const borderColor = corPredefinida
    ? clsx({
        "text-danger": color === "red",
        "text-primary": color === "blue",
        "text-white": color === "white",
        "text-secondary": color === "gray",
        "text-dark": color === "black",
        "text-success": color === "green",
        "text-custom": color === "#34a69c", // Personalizado
      })
    : isButton
    ? "text-white"
    : "text-custom"; // Define a cor padrão

  return (
    <div
      className={clsx("spinner-border", size, borderColor)}
      role="status"
    >
      <span className="visually-hidden">Carregando...</span>
    </div>
  );
};

export default Loading;
