import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import Loading from "../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUser } from "../../store/slices/authSlice";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom"; // Importa o useNavigate
import "./../../styles/AreaDoCliente.css";

const AreaDoCliente = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  const navigate = useNavigate(); // Hook para navegação

  useEffect(() => {
    if (token) {
      navigate("/"); // Redireciona corretamente
    }

    document.title = "Íkone | Área do Cliente";
  }, [token, navigate]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `http://localhost:5000/api/Usuario/Login?login=${email}&senha=${password}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error(errorData);
        throw new Error(errorData.mensagem);
      }

      const data = await response.json();

      console.log(data);

      if (!data.token) {
        console.error("Erro: resposta inesperada da API", data);
        toast.error("Erro ao autenticar. Verifique suas credenciais.");
        return;
      }

      console.log(data);
      console.log(data.usuario);

      const decodedToken = decodeJWT(data.token);
      const expiration = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/expiration'];
      console.log(expiration);

      dispatch(setToken(data.token));
      dispatch(setUser(data.usuario));
      toast.success("Login efetuado com sucesso!");
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      } else {
        toast.error("Ocorreu um erro desconhecido.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const decodeJWT = (token : string) => {
    try {
      const base64Url = token.split(".")[1]; // Obtém a parte do payload
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );
  
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error("Token inválido", error);
      return null;
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100 bg-light px-3">
      <div className="w-25 w-md-50 w-sm-75 w-100 max-w">
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-column p-4 shadow rounded bg-light"
        >
          <h3 className="text-center mb-3">Entre no sistema</h3>
          <div className="w-100">
            <label htmlFor="email" className="mb-1">
              E-mail
            </label>
            <input
              type="text"
              name="email"
              placeholder="Digite seu e-mail"
              className="mb-3 form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="senha" className="mb-1">
              Senha
            </label>
            <input
              type="password"
              name="senha"
              placeholder="Digite sua senha"
              className="mb-3 form-control"
              id="senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button
              disabled={isLoading}
              type="submit"
              className="btn btn-primary mt-3 w-100"
            >
              {isLoading ? (
                <Loading isButton={true} corPredefinida color="white" />
              ) : (
                "Entrar"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AreaDoCliente;
