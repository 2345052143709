import { Facebook, Instagram, Linkedin, MessageCircle } from "lucide-react";

function Footer() {
  return (
    <footer className="bg-dark text-light mt-5 py-5">
      <div className="container">
        <div className="row">
          {/* Coluna 1 - Logo e Descrição */}
          <div className="col-md-3">
            <h5 className="text-uppercase">Íkone</h5>
            <p className="small">Mensagem aqui</p>
          </div>

          {/* Coluna 2 - Links Rápidos */}
          <div className="col-md-3">
            <h5 className="text-uppercase">Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-light text-decoration-none">
                  Início
                </a>
              </li>
              <li>
                <a href="#contato" className="text-light text-decoration-none">
                  Contato
                </a>
              </li>
              <li>
                <a href="#produtos" className="text-light text-decoration-none">
                  Produtos
                </a>
              </li>
            </ul>
          </div>

          {/* Coluna 3 - Contato */}
          <div className="col-md-3">
            <h5 className="text-uppercase">Contato</h5>
            <ul className="list-unstyled">
              <li>📍 Av. Exemplo, 123 - SP</li>
              <li>📞 (11) 99999-9999</li>
              <li>✉ contato@ikone.com</li>
            </ul>
          </div>

          {/* Coluna 4 - Redes Sociais */}
          <div className="col-md-3">
            <h5 className="text-uppercase">Redes Sociais</h5>
            <div className="d-flex gap-3">
              <a href="/" className="text-light fs-4">
                <Facebook size={24} />
              </a>
              <a href="/" className="text-light fs-4">
                <Instagram size={24} />
              </a>
              <a href="/" className="text-light fs-4">
                <Linkedin size={24} />
              </a>
              <a href="/" className="text-light fs-4">
                <MessageCircle size={24} />
              </a>
            </div>
          </div>
        </div>

        {/* Linha de Copyright */}
        <div className="text-center mt-4">
          <p className="mb-0 small">
            © 2025 - Íkone. Todos os direitos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
