import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice"; // Importa o authSlice
import loginReducer from "./slices/loginSlice"; // Se já existir

export const store = configureStore({
  reducer: {
    auth: authReducer,
    login: loginReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
