import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Carousel } from "primereact/carousel";

import InfiniteCarousel from "../components/InfiniteCarousel";
import Navegacao from "../components/Nav";
import NossosProdutos from "../components/NossosProdutos";
import Footer from "../components/Footer";
import Contato from "../components/Contato";
import QuemSomos from "../components/QuemSomos";

import imagem1 from "../assets/img/Carousel/RaioxManaus.png";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const Inicio = () => {
  const [products, setProducts] = useState([
    { id: 1, name: "Produto 1", image: imagem1 },
    { id: 2, name: "Produto 2", image: imagem1 },
    { id: 3, name: "Produto 3", image: imagem1 },
  ]);

  const responsiveOptions = [
    { breakpoint: "1024px", numVisible: 3, numScroll: 3 },
    { breakpoint: "768px", numVisible: 2, numScroll: 2 },
    { breakpoint: "560px", numVisible: 1, numScroll: 1 },
  ];

  const productTemplate = (product: any) => (
    <div className="product-item">
      <img src={product.image} className="product-image" alt="" />
    </div>
  );

  useEffect(() => {
    document.title = "Íkone | Página Inicial";
    AOS.init();
    AOS.refresh();
  }, []);

  const token = useSelector((state: RootState) => state.auth.token);

  return (
    <>
      <Navegacao userId={token} imageUrl="b" />
      <Carousel
        value={products}
        numVisible={1}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        className="custom-carousel mt-3"
        circular
        autoplayInterval={4000}
        itemTemplate={productTemplate}
      />
      <NossosProdutos />
      <QuemSomos />
      <InfiniteCarousel />
      <Contato />
      <Footer />
    </>
  );
};

export default Inicio;
