import { Mail, Phone } from "lucide-react";
import React from "react";

const Contato = () => {
  const handleSendMessage = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    alert("Mensagem enviada com sucesso!");
  };

  return (
    <div className="container mt-4" id="contato">
      <h1 data-aos="fade-up">Contato</h1>

      {/* Container Responsivo */}
      <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center gap-4">
        {/* Informações de Contato */}
        <div className="text-center w-100 w-lg-50" data-aos="fade-right">
          <h5>Informações adicionais de Contato</h5>
          <div className="mt-2 text-center">
            <p>
              <span>
                <Phone size={20} className="me-2" />
              </span>{" "}
              (11) 98145-8600
            </p>
            <p>
              <span>
                <Phone size={20} className="me-2" />
              </span>{" "}
              (11) 96337-0510
            </p>
            <p>
              <span>
                <Mail size={20} className="me-2" />
              </span>
              ikone@ikoneautomacao.com
            </p>
          </div>
        </div>

        {/* Formulário */}
        <form
          className="w-100 w-lg-50"
          data-aos="fade-left"
          onSubmit={handleSendMessage}
        >
          <label htmlFor="nome">
            Nome <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="Digite seu nome"
            name="nome"
            className="form-control mb-2 mt-1"
          />

          <label htmlFor="email">
            Email <span className="text-danger">*</span>
          </label>
          <input
            type="email"
            placeholder="Digite seu email"
            name="email"
            className="form-control mb-2 mt-1"
          />

          <label htmlFor="telefone">
            Telefone <span className="text-danger">*</span>
          </label>
          <input
            type="tel"
            placeholder="Digite seu telefone"
            name="telefone"
            className="form-control mb-2 mt-1"
          />

          <label htmlFor="mensagem">
            Mensagem <span className="text-danger">*</span>
          </label>
          <textarea
            name="mensagem"
            placeholder="Digite sua mensagem"
            className="form-control mb-3 mt-1"
            rows={5}
          ></textarea>

          <button type="submit" className="btn btn-primary w-100">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contato;
