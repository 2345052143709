import React from "react";
import "./../styles/QuemSomos.css"; // Adicione um arquivo CSS para estilização extra

const QuemSomos = () => {
  return (
    <div className="container quem-somos-banner mt-5">
      {/* Seção de introdução com fundo estilizado */}
      <div className="container-banner-quem-somos d-flex flex-column justify-content-center align-items-center text-white text-center">
        <h1 className="quem-somos-titulo">Quem Somos</h1>
        <p className="quem-somos-subtitulo">
          Sempre destacando-se pela excelência do atendimento e assistência
          técnica. A ÍKONE é uma empresa que busca oferecer soluções
          consistentes e duradouras em automação, eletromecânica e TI, desde a
          análise das necessidades do cliente, seja desenvolvimento ou upgrades,
          até à startup.
        </p>
      </div>
    </div>
  );
};

export default QuemSomos;
