import { createSlice } from "@reduxjs/toolkit";

interface ChamadoState {
  lista: string[];
}

const initialState: ChamadoState = {
  lista: [],
};

const chamadoSlice = createSlice({
  name: "chamado",
  initialState,
  reducers: {
    adicionarChamado: (state, action) => {
      state.lista.push(action.payload);
    },
  },
});

export const { adicionarChamado } = chamadoSlice.actions;
export default chamadoSlice.reducer;
